import LocomotiveScroll from 'locomotive-scroll';
const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),

    smooth: true,
    // multiplier: .8,
    touchMultiplier: 1,
    getDirection: true,
    getSpeed: true,
    scrollFromAnywhere: false,
    smartphone: {
        smooth: false,
        direction: 'vertical'
    },
    tablet: {
        smooth: false,
        direction: 'vertical'
    },
});


export { scroll };