import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import LocomotiveScroll from 'locomotive-scroll';
const locomotiveScroll = new LocomotiveScroll({
    smooth: true,
    scrollCallback: function () {
        ScrollTrigger.update()
    }
});

locomotiveScroll.lenisInstance.options.content.addEventListener('wheel', (event) => {
    const paths = event.composedPath();

    paths.find((el) => {
        if(!el) return;
        if(!(el instanceof HTMLElement)) return;

        const elClass = el.classList;
        if((elClass.contains('custom-options') || elClass.contains('header__nav'))) {
            event.stopPropagation();

            return;
        }
    })
});

export { locomotiveScroll };