import { gsap } from 'gsap/dist/gsap';

const duration = 5;
const activeClass = "slider__slides__item--active";

export function initSlider(){
    const sliders = document.querySelectorAll(".slider");

    sliders.forEach(slider => {
        const panels = slider.querySelectorAll(".slider__slides__item");

        if(panels.length > 0){
            let sliderTimeout = undefined;
            const dotCont = slider.querySelector(".slider__dots");
            let currActive = 0

            for(let i = 0; i < panels.length; i++){
                const dot = document.createElement("button");
                dot.classList.add("slider__dots__item");
    
                dotCont?.appendChild(dot);
    
                dot.addEventListener("click", function (){
                    currActive = i;
                    setActive(currActive, panels, dots)
    
                    window.clearInterval(sliderTimeout);
                    sliderTimeout = setInterval(() => {
                        currActive++
                        if(currActive >= panels.length) currActive = 0;
    
                        setActive(currActive, panels, dots)
                    }, duration * 1000);
                });
            }
    
            const dots = slider.querySelectorAll(".slider__dots__item");
            setActive(currActive, panels, dots)
    
            sliderTimeout = setInterval(() => {
                currActive++
                if(currActive >= panels.length) currActive = 0;
    
                setActive(currActive, panels, dots)
            }, duration * 1000);
    

            const prevArrow = slider.querySelector(".slider__arrows__item--prev");
            const nextArrow = slider.querySelector(".slider__arrows__item--next");
        
            prevArrow?.addEventListener("click", function (){
                currActive--;
                if(currActive < 0) currActive = panels.length - 1;
                setActive(currActive, panels, dots)
    
                window.clearInterval(sliderTimeout);
                sliderTimeout = setInterval(() => {
                    currActive++
                    if(currActive >= panels.length) currActive = 0;
    
                    setActive(currActive, panels, dots)
                }, duration * 1000);
            });
    
            nextArrow?.addEventListener("click", function (){
                currActive++
                if(currActive >= panels.length) currActive = 0;
                setActive(currActive, panels, dots)
    
                window.clearInterval(sliderTimeout);
                sliderTimeout = setInterval(() => {
                    currActive++
                    if(currActive >= panels.length) currActive = 0;
    
                    setActive(currActive, panels, dots)
                }, duration * 1000);
            });

        }else{
            panels[0].classList.add(activeClass);
            slider.querySelector(".slider__arrows")?.remove();
        }
    });
}

function setActive(num, panels, dots){
    panels.forEach(panel => panel.classList.remove(activeClass));
    panels[num].classList.add(activeClass);

    dots.forEach(dot => dot.classList.remove("slider__dots__item--active"));
    dots[num].classList.add("slider__dots__item--active");
}